import axios from 'axios';
import { rootWithLanguage } from '../utils/paths';
import Autocomplete from '../components/search/autocomplete';

class FaqOverview {
  constructor() {
    this.dom = {
      searchForm: document.getElementById('search-form'),
    };

    this.data = {
      // https://www.fusejs.io/api/options.html#options
      fuseOptions: {
        isCaseSensitive: false,
        shouldSort: true,
        minMatchCharLength: 3,
        threshold: 0.3,
        includeMatches: true,
        keys: ['title', 'keywords'],
      },
      autocompleteOptions: {
        maxResults: 5,
        itemHeading: 'category_name',
        itemName: 'title',
        itemUrl: 'url',
      },
      questionsApiUrl: `${rootWithLanguage}faq?action=list-auto-complete`,
    };

    this.autocomplete = null;

    this.mount();
  }

  async mount() {
    if (this.dom.searchForm) {
      try {
        // fetch data
        const response = await axios.get(this.data.questionsApiUrl);

        // initialize autocomplete
        this.autocomplete = new Autocomplete({
          autocompleteContainer: this.dom.searchForm,
          data: response.data.results ?? [],
          autocompleteOptions: this.data.autocompleteOptions,
          fuseOptions: this.data.fuseOptions,
        });
      } catch (error) {
        throw Error(error);
      }
    }
  }
}

if (document.getElementById('page-faq-overview')) {
  // eslint-disable-next-line no-new
  new FaqOverview();
}
