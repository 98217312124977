import { getScrollLookup } from '@/js/utils/gsap';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

document
  .querySelectorAll('.js-scroll-to')
  .forEach((el) => {
    el.addEventListener('click', (e) => {
      const target = document.getElementById(el.hash.substring(1));
      if (target) {
        e.preventDefault();

        const getScroll = getScrollLookup([target], {
          start: 'top 60px',
        });

        /*
           Using scrollTo alongside scroll-behavior: smooth in CSS will cause conflicts.
           Since we already got css smooth scrolling, we need to set duration to 0 and ease to none.
           If we need more control over the animation, then set scroll behavior to auto in CSS.
         */
        gsap.to(window, {
          duration: 0,
          ease: 'none',
          scrollTo: getScroll(target),
          overwrite: 'auto',
        });
      }
    });
  });
