import SwiperCategories from '../components/swiper/SwiperCategories';

class Home {
  constructor() {
    this.dom = {
      swiperCategories: document.querySelectorAll('.swiper-categories'),
    };

    this.mount();
  }

  mount() {
    this.dom.swiperCategories.forEach((swiperCategory) => new SwiperCategories(swiperCategory));
  }
}

if (document.getElementById('page-home')) {
  // eslint-disable-next-line no-new
  new Home();
}
