const footer = document.getElementById('site-footer');

const setFooterPosition = () => {
  if (footer.getBoundingClientRect().height > window.innerHeight) {
    footer.classList.replace('position-sticky', 'position-static');
  } else {
    footer.classList.replace('position-static', 'position-sticky');
  }
};

if (footer) {
  setFooterPosition();
  window.addEventListener('resize', () => setFooterPosition(), {
    passive: true,
  });
}
