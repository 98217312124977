import Swiper from 'swiper';

export default class SwiperCategories {
  constructor(swiper, swiperOptions) {
    this.dom = {
      swiper,
    };

    this.data = {
      swiper: undefined,
      breakpoint: window.matchMedia('(min-width: 768px)'),
      options: {
        ...{
          slidesPerView: 'auto',
          spaceBetween: 15,
          freeMode: true,
          breakpoints: {},
        },
        ...swiperOptions,
      },
    };

    this.mount();
  }

  mount() {
    this.data.breakpoint.addEventListener('change', () => this.breakpointChecker());
    this.breakpointChecker();
  }

  initSwiper() {
    this.data.swiper = new Swiper(this.dom.swiper, this.data.options);
  }

  breakpointChecker() {
    if (this.data.breakpoint.matches) {
      if (this.data.swiper) this.data.swiper.destroy(true, true);
    } else {
      this.initSwiper();
    }
  }
}
