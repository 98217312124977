/* global grecaptcha */

export default class GoogleRecaptcha {
  /**
   * @param action {{string}}
   */
  constructor({ action }) {
    this.props = {
      action,
    };

    this.data = {
      key: '6LcU_FspAAAAAOw2MQkF1hSNnJeahhy9iY97p2-Z',
    };

    this.mount();
  }

  mount() {
    if (!window.grecaptcha) {
      this.initializeRecaptcha();
    }
  }

  initializeRecaptcha() {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${this.data.key}`;
    document.body.appendChild(script);
  }

  /**
   * @param callback {function}
   */
  requestToken(callback) {
    if (typeof grecaptcha.ready !== 'function') {
      return;
    }

    grecaptcha.ready(() => {
      grecaptcha.execute(this.data.key, { action: this.props.action })
        .then((token) => {
          callback(token);
        });
    });
  }
}
