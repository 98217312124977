export function sendForm({ name }) {
  window.dataLayer = window.dataLayer || [];
  // Remarketing Tag Data
  window.dataLayer.push({
    event: 'successful_form_submit',
    formName: name,
  });
}

export default {
  sendForm,
};
