import Fuse from 'fuse.js';
import { groupBy } from 'lodash-es';
import { highlight } from '@/js/utils/fuse';

/*
<!-- here form is the autocompleteContainer -->
<form>
  <input class="js-autocomplete-search" aria-label="search">
  <div class="position-relative">
    <ol class="js-autocomplete-results-list"></ol>
  </div>
</form>
 */

export default class Autocomplete {
  /**
   * @param {HTMLElement} autocompleteContainer
   * @param {Array} data
   * @param {Object} autocompleteOptions
   * @param {Object} fuseOptions
   */
  constructor({
    autocompleteContainer, data, autocompleteOptions, fuseOptions,
  }) {
    this.dom = {
      autocomplete: autocompleteContainer,
      searchInput: autocompleteContainer.querySelector('.js-autocomplete-search'),
      resultsList: autocompleteContainer.querySelector('.js-autocomplete-results-list'),
    };

    this.data = {
      // https://www.fusejs.io/api/options.html#options
      fuseOptions,
      autocompleteOptions: {
        ...{
          maxResults: 5,
          itemHeading: '',
          itemName: '',
          itemUrl: '',
          dropdownItemClass: 'dropdown-item',
        },
        ...autocompleteOptions,
      },
      data,
      fuse: null,
      results: [],
    };

    this.mount();
  }

  mount() {
    this.dom.searchInput.addEventListener('input', () => this.onSearchInput());
    this.dom.searchInput.addEventListener('focus', () => this.onSearchFocus());
    this.dom.searchInput.addEventListener('keyup', (event) => this.onSearchKeyUp(event));
    document.addEventListener('click', (event) => this.onDocumentClick(event));

    if (this.dom.autocomplete.tagName.toLowerCase() === 'form') {
      this.dom.autocomplete.addEventListener('submit', (event) => this.onSearchSubmit(event));
    }

    this.initFuse();
  }

  unmount() {
    this.dom.searchInput.removeEventListener('input', () => this.onSearchInput());
    this.dom.searchInput.removeEventListener('focus', () => this.onSearchFocus());
    this.dom.searchInput.removeEventListener('keyup', (event) => this.onSearchKeyUp(event));
    document.removeEventListener('click', (event) => this.onDocumentClick(event));

    if (this.dom.autocomplete.tagName.toLowerCase() === 'form') {
      this.dom.autocomplete.removeEventListener('submit', (event) => this.onSearchSubmit(event));
    }
  }

  showResults() {
    this.dom.resultsList.classList.add('show');
  }

  hideResults() {
    this.dom.resultsList.classList.remove('show');
    this.dom.searchInput.classList.remove('has-matches');
  }

  onSearchInput() {
    this.data.results = this.getResults(this.dom.searchInput.value);
    this.dom.resultsList.innerHTML = '';
    this.dom.searchInput.classList.toggle('has-matches', this.data.results.length > 0);

    // check if we have a heading
    const hasHeadings = this.data.results.some((obj) => obj.item[this.data.autocompleteOptions.itemHeading]);

    if (this.data.results.length > 0) {
      this.showResults();
    } else {
      this.hideResults();
    }

    if (hasHeadings) {
      const groupedResults = groupBy(this.data.results, (obj) => obj.item[this.data.autocompleteOptions.itemHeading]);

      Object.entries(groupedResults).forEach(([category, data]) => {
        const liHeader = document.createElement('li');
        liHeader.innerHTML = `<div class="dropdown-header">${category}</div>`;
        this.dom.resultsList.appendChild(liHeader);
        this.buildListItems(data);
      });
    } else {
      this.buildListItems(this.data.results);
    }
  }

  onSearchFocus() {
    if (this.data.results.length > 0) {
      this.showResults();
    }
  }

  /**
   * @param {KeyboardEvent} event
   */
  onSearchKeyUp(event) {
    if (event.key === 'Escape') {
      this.hideResults();
    }
  }

  /**
   * @param {MouseEvent} event
   */
  onDocumentClick(event) {
    if (event.target.classList.contains(this.data.autocompleteOptions.dropdownItemClass) || event.target.closest(this.data.autocompleteOptions.dropdownItemClass)) {
      this.hideResults();
    } else if (!this.dom.autocomplete.contains(event.target)) {
      this.hideResults();
    }
  }

  /**
   * @param {SubmitEvent} event
   */
  onSearchSubmit(event) {
    event.preventDefault();

    if (this.data.results.length > 0) {
      window.location.href = this.data.results[0].item.url;
    }
  }

  getResults(searchValue) {
    return this.data.fuse.search(searchValue).slice(0, this.data.autocompleteOptions.maxResults);
  }

  buildListItems(data) {
    data.forEach((obj) => {
      const liItem = document.createElement('li');
      liItem.innerHTML = `
        <a class="${this.data.autocompleteOptions.dropdownItemClass}" href="${obj.item[this.data.autocompleteOptions.itemUrl]}">
          ${highlight(obj.item[this.data.autocompleteOptions.itemName], obj.matches, [this.data.autocompleteOptions.itemName])}
        </a>
      `;
      this.dom.resultsList.appendChild(liItem);
    });
  }

  initFuse() {
    this.data.fuse = new Fuse(this.data.data, this.data.fuseOptions);
  }
}
