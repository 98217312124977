// components
import Validation from '@/js/components/forms/Validation';
import * as datalayer from '@/js/utils/datalayer';

class Offer {
  constructor() {
    this.dom = {
      formOffer: document.getElementById('form-offer'),
    };

    this.components = {
      offerFormValidation: null,
    };

    this.mount();
  }

  mount() {
    if (this.dom.formOffer) {
      this.components.offerFormValidation = new Validation(this.dom.formOffer);

      this.components.offerFormValidation.dom.formEl.addEventListener(this.components.offerFormValidation.customEvents.success, () => {
        datalayer.sendForm({ name: 'Offerte formulier' });
      });
    }
  }
}

if (document.getElementById('page-offer') || document.getElementById('page-product')) {
  // eslint-disable-next-line no-new
  new Offer();
}
