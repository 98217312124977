import { Dropdown } from 'bootstrap';

export default class MultiLevelDropdownMenu {
  constructor(menu) {
    this.dom = {
      menu,
      dropdowns: menu.querySelectorAll('.offcanvas .dropdown'),
      topBar: menu.querySelector('.offcanvas-top-bar'),
      prevStepButton: document.getElementById('btn-offcanvas-prev-step'),
      prevStepText: document.querySelector('#btn-offcanvas-prev-step .text'),
      offcanvas: document.getElementById('site-nav-offcanvas'),
    };

    this.data = {
      history: [],
      historyProxy: undefined,
    };

    this.mount();
  }

  mount() {
    this.dom.dropdowns.forEach((dropdown) => dropdown.addEventListener('show.bs.dropdown', (e) => this.onDropdownShow(e)));
    this.dom.dropdowns.forEach((dropdown) => dropdown.addEventListener('hide.bs.dropdown', (e) => this.onDropdownHide(e)));
    this.dom.offcanvas.addEventListener('hidden.bs.offcanvas', () => this.onCanvastHidden());
    this.dom.prevStepButton.addEventListener('click', () => this.goBack());
  }

  onDropdownShow(e) {
    this.data.history.push({
      dropdown: e.currentTarget.querySelector('[data-bs-toggle="dropdown"]'),
      name: e.target.textContent,
    });

    this.onHistoryChange();
  }

  onDropdownHide(e) {
    this.data.history.filter((item) => item.dropdown !== e.currentTarget);
    this.onHistoryChange();
  }

  onCanvastHidden() {
    this.data.history.forEach(() => this.goBack());
  }

  onHistoryChange() {
    if (this.data.history.length > 0) {
      this.dom.prevStepText.textContent = this.data.history.at(-1).name;
      this.dom.topBar.classList.add('has-prev-step');
    } else {
      this.dom.prevStepText.textContent = '';
      this.dom.topBar.classList.remove('has-prev-step');
    }
  }

  goBack() {
    const dropdown = Dropdown.getInstance(this.data.history.at(-1).dropdown);
    dropdown.hide();
    this.data.history.pop();
    this.onHistoryChange();
  }
}
