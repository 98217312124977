import { debounce } from 'lodash-es';

const root = document.querySelector(':root');

function getHeaderHeight() {
  const siteNav = document.getElementById('site-nav');
  return siteNav ? `${siteNav.offsetHeight}px` : 0;
}

function getLayoutIntroHeight() {
  const layoutIntro = document.querySelector('.l-intro');

  if (layoutIntro) {
    const { height } = layoutIntro.getBoundingClientRect();
    const paddingTop = parseFloat(window.getComputedStyle(layoutIntro).paddingTop);
    const paddingBottom = parseFloat(window.getComputedStyle(layoutIntro).paddingTop);
    return `${height + paddingTop + paddingBottom}px`;
  }
  return 0;
}

function getWindowHeight() {
  return `${window.innerHeight}px`;
}

function debouncedResizeHandler() {
  root.style.setProperty('--site-nav-height', getHeaderHeight());
  root.style.setProperty('--layout-intro-height', getLayoutIntroHeight());
}

root.style.setProperty('--site-nav-height', getHeaderHeight());
root.style.setProperty('--layout-intro-height', getLayoutIntroHeight());
root.style.setProperty('--vh-fix', getWindowHeight());

window.addEventListener('resize', debounce(debouncedResizeHandler, 100));
