/* eslint-disable import/no-import-module-exports */
import './styles/index.scss';

// vendor
import 'bootstrap/js/dist/alert';

import { Dropdown, Offcanvas, Modal } from 'bootstrap';
import MouseFollower from 'mouse-follower';
import gsap from 'gsap';
import LayeredSections from './js/components/layered_sections/LayeredSections';
// views
import './js/views';

// init dropdown
document
  .querySelectorAll('.dropdown')
  .forEach((dropdownNode) => new Dropdown(dropdownNode));

// init modal
document
  .querySelectorAll('.modal')
  .forEach((modalNode) => new Modal(modalNode));

// init offcanvas
document
  .querySelectorAll('.offcanvas')
  .forEach((offcanvasNode) => new Offcanvas(offcanvasNode));

// init layered sections
const layeredSection = document.querySelectorAll('.l-layered-sections');
layeredSection.forEach((section) => new LayeredSections(section));

if (document.getElementById('site-footer')) {
  MouseFollower.registerGSAP(gsap);

  // eslint-disable-next-line no-new
  new MouseFollower({
    container: document.getElementById('site-footer'),
    className: 'mf-cursor mf-cursor--site-footer',
  });
}

(function loadIcons(r) {
  r.keys()
    .forEach(r);
}(require.context('./img/icons', true, /\.svg$/)));

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) module.hot.accept();
