import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default class LayeredSections {
  constructor(sectionWrapper) {
    this.dom = {
      sectionWrapper,
      imageSections: [...sectionWrapper.querySelectorAll('.section-image')],
      imageSectionsToAnimate: [...sectionWrapper.querySelectorAll('.section-image:not(:last-child)')],
      textSectionWrap: sectionWrapper.querySelector('.section-text-wrap'),
      textSections: [...sectionWrapper.querySelectorAll('.section-text')],
      images: [...sectionWrapper.querySelectorAll('.section-image img')],
    };

    this.data = {
      imagePaths: this.dom.images.map((img) => img.src),
      timelines: [],
      sectionScrollTrigger: undefined,
    };

    this.mount();
  }

  mount() {
    // load all images
    Promise.all(this.data.imagePaths.map((path) => new Promise((resolve) => {
      const image = new Image();
      image.onload = () => resolve();
      image.onerror = () => resolve();
      image.src = path;
    })))
      .then(() => {
        gsap.matchMedia()
          .add('(min-width: 768px)', () => {
            this.initImageSections();
            this.initTextSections();

            this.data.sectionScrollTrigger = ScrollTrigger.create({
              trigger: this.dom.sectionWrapper,
              scrub: true,
              pin: true,
              start: () => 'top top',
              end: () => `+=${(this.dom.imageSectionsToAnimate.length) * window.innerHeight}`,
            });

            return () => {
              this.data.timelines.forEach((timeline) => {
                timeline.kill();
                // eslint-disable-next-line no-param-reassign
                timeline = null;
              });

              this.data.timelines = [];

              if (this.data.sectionScrollTrigger) this.data.sectionScrollTrigger.kill();
            };
          });
      });
  }

  initImageSections() {
    // set z-index
    gsap.set(this.dom.imageSections, { zIndex: (i, target, targets) => targets.length - i });

    // create timeline
    this.dom.imageSectionsToAnimate.forEach((image, i) => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: this.dom.sectionWrapper,
          start: () => `top -${window.innerHeight * i}`,
          end: () => `+=${window.innerHeight}`,
          scrub: true,
          toggleActions: 'play none reverse none',
        },
      });

      // animate
      tl.to(image, {
        height: 0,
        ease: 'none',
      });

      this.data.timelines.push(tl);
    });
  }

  initTextSections() {
    // create timeline
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: this.dom.sectionWrapper,
        start: () => 'top top',
        end: () => `+=${(this.dom.imageSectionsToAnimate.length) * window.innerHeight}`,
        scrub: true,
        toggleActions: 'play none reverse none',
      },
    });

    // animate
    tl
      .to(this.dom.textSectionWrap, {
        y: `-${100 * (this.dom.imageSectionsToAnimate.length)}%`,
        ease: 'none',
      });

    this.data.timelines.push(tl);
  }
}
