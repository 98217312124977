import axios from 'axios';
import GoogleRecaptcha from '@/js/components/google_recaptcha/recaptcha';

export default class Validation {
  /**
   * @param formEl {HTMLElement}
   */
  constructor(formEl) {
    this.dom = {
      formEl,
      fields: [],
      message: formEl.querySelector('.js-message'),
    };

    this.components = {
      recaptcha: null,
    };

    this.states = {
      idle: 'STATE_IDLE',
      awaitingRequest: 'STATE_AWAITING_REQUEST',
    };

    this.props = {
      url: formEl.getAttribute('action') || '',
    };

    this.data = {
      invalidClass: 'is-invalid',
      message: {
        text: null,
        class: null,
      },
      formValidationClass: 'was-validated',
      state: this.states.idle,
      errors: null,
      recaptchaAction: 'form_submit',
    };

    this.events = {
      onFormSubmit: this.onFormSubmit.bind(this),
      onRequestSuccess: this.onRequestSuccess.bind(this),
      onRequestError: this.onRequestError.bind(this),
    };

    this.customEvents = {
      success: 'success',
      error: 'error',
    };

    this.mount();
  }

  mount() {
    if (this.dom.formEl) {
      this.dom.formEl.addEventListener('submit', this.events.onFormSubmit);

      this.components.recaptcha = new GoogleRecaptcha({ action: this.data.recaptchaAction });
    }
  }

  /**
   * form has been submitted
   * @param e {Event}
   */
  onFormSubmit(e) {
    // stay on page
    e.preventDefault();

    // awaiting server response, ignore click
    if (this.data.state === this.states.awaitingRequest) return;

    // reset message state
    this.resetMessage();

    // set fields
    this.dom.fields = [...this.dom.formEl.querySelectorAll('[name]:not([type="hidden"])')];

    // disallow form submit
    this.data.state = this.states.awaitingRequest;

    // set formData
    const formData = new FormData(this.dom.formEl);

    this.components.recaptcha.requestToken((token) => {
      formData.append('recaptcha-action', this.data.recaptchaAction);
      formData.append('recaptcha-token', token);

      // send request
      axios.post(this.props.url, formData)
        .then(this.events.onRequestSuccess)
        .catch(this.events.onRequestError);
    });
  }

  /**
   * request was successful
   * @param response {Object}
   */
  onRequestSuccess(response) {
    this.data.errors = response.data.errors || [];
    this.parseMessage(response.data.message);
    this.setMessage();

    if (this.hasErrors()) {
      // form has errors
      this.handleErrors();
    } else {
      // form was submitted successfully
      this.handleSuccess();
    }

    // allow form submit
    this.data.state = this.states.idle;
  }

  /**
   * request has failed
   * @param error {Object}
   */
  onRequestError(error) {
    console.error(error);

    // allow form submit
    this.data.state = this.states.idle;
  }

  hasErrors() {
    if (this.data.errors && Object.keys(this.data.errors).length > 0) {
      return true;
    }

    return this.data.message.type === 'error';
  }

  /**
   * parse message object
   * @param messageObj {Object}
   */
  parseMessage(messageObj) {
    switch (messageObj?.type) {
      case 'error':
        this.data.message.class = 'alert-danger';
        break;
      case 'success':
        this.data.message.class = 'alert-success';
        break;
      default:
        this.data.message.class = null;
        break;
    }

    this.data.message.text = messageObj?.text;
    this.data.message.type = messageObj?.type;
  }

  // show message
  setMessage() {
    // if we have no message, exit fn
    if (!this.data.message.text) return;

    // apply classes
    if (this.data.message.class) {
      this.dom.message.classList.add(this.data.message.class);
    }

    // apply text
    this.dom.message.textContent = this.data.message.text;

    // show message
    this.dom.message.classList.remove('d-none');

    // scroll to message
    this.dom.message.scrollIntoView({ behavior: 'smooth' });
  }

  // empty message and hide it
  resetMessage() {
    // if we have no message element, exit fn
    if (!this.data.message) return;

    // reset class
    this.dom.message.classList.add('d-none');
    if (this.data.message.class) {
      this.dom.message.classList.remove(this.data.message.class);
    }
    // reset text
    this.dom.message.textContent = '';
  }

  // form was submitted successfully
  handleSuccess() {
    // hide validation states
    this.dom.formEl.classList.remove(this.data.formValidationClass);

    // reset form
    this.dom.formEl.reset();

    this.dom.formEl.dispatchEvent(new CustomEvent(this.customEvents.success));
  }

  // form has errors
  handleErrors() {
    this.dom.fields.forEach((field) => {
      const error = Object.entries(this.data.errors)
        .find(([key]) => field.name === key);

      if (!error) {
        if (field.validity.customError) {
          // Remove custom error if is set
          field.setCustomValidity('');
        }

        return;
      }

      // Get the value
      const [, value] = error;

      // set custom error
      field.setCustomValidity(value.toString());

      const invalidFeedback = field.parentNode.querySelector('.invalid-feedback');

      // set error
      if (invalidFeedback) {
        invalidFeedback.innerHTML = value.toString();
      }
    });

    // show errors
    this.dom.formEl.classList.add(this.data.formValidationClass);
  }
}
